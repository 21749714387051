import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { useParams } from "react-router-dom";

function PostDetail() {
  const params = useParams();
  //   console.log(params);

  const [singlePost, setSinglePost] = useState();
  let [singlePostHeading, setSinglePostHeading] = useState();
  let [singlePostContent, setSinglePostContent] = useState();
  let [singlePostDate, setSinglePostDate] = useState();
  let [singlePostImg, setSinglePostImg] = useState();

  useEffect(() => {
    async function singlePostDetailPage() {
      // try {
      let singlePostResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/posts/${params.postId}`
      );
      let singlePostData = await singlePostResponse.json();
      setSinglePost(singlePostData);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    }
    singlePostDetailPage();
  }, [params.postId]);

  useEffect(() => {
    if (singlePost) {
      // console.log(singlePost);
      setSinglePostHeading(singlePost.title.rendered);
      setSinglePostContent(singlePost.content.rendered);
      setSinglePostDate(singlePost.date);
      const postLink = async () => {
        let response = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${singlePost.featured_media}`
        );
        let data = await response.json();
        if (data && data.guid) {
          setSinglePostImg(data.guid.rendered);
        }
      };
      postLink();
    }
  }, [singlePost]);

  return (
    <>
      <div className="container blog-post-view">
        <div className="blog-post-row">
          <div className="blog-img">
            {/* <LazyLoad className="postImg blog-img"> */}
            <img
              src={singlePostImg ? singlePostImg : null}
              className="img-fluid"
              alt=""
              loading="eager"
            />
            {/* </LazyLoad> */}
          </div>
          <div className="blog-content">
            <div className="heading-date">
              <h1>{singlePostHeading}</h1>
              <h5>
                Blogbeitrag :{" "}
                <span>
                  {singlePostDate ? singlePostDate.split("T")[0] : null}
                </span>
              </h5>
            </div>
            <div
              className="editor-content"
              dangerouslySetInnerHTML={{
                __html: singlePostContent,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostDetail;
