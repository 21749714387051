import React, { useEffect, useState } from "react";
// import LazyLoad from "react-lazy-load";
// import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function TopEmployer() {
  let [topEmployer, setTopEmployer] = useState([]);
  const [bannerTitle, setBannerTitle] = useState();
  const [bannerImg, setBannerImg] = useState();
  let [visibleList, setVisibleList] = useState(10);

  useEffect(() => {
    const JobSkillCount = async () => {
      // try {
      let jobSkillResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-TopEmployer`
      );
      let jobSkillData = await jobSkillResponse.json();
      setTopEmployer(jobSkillData.data);
      // } catch (error) {
      //   console.log("Error: ", error);
      // }
    };
    JobSkillCount();
  }, []);

  useEffect(() => {}, [topEmployer]);

  useEffect(() => {
    const bannerDetail = async () => {
      // try {
      let bannerResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/667`
      );
      let bannerData = await bannerResponse.json();
      setBannerTitle(bannerData.title.rendered);
      // } catch (error) {
      //   // console.log("Error: ",error);
      // }
    };
    bannerDetail();
  }, []);

  useEffect(() => {
    const ACL_page_images = async () => {
      let ACLpageimagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imagesResponse = await ACLpageimagesResponse.json();
      setBannerImg(imagesResponse.data.ACL_page);
    };
    ACL_page_images();
  }, []);

  useEffect(() => {}, [bannerImg, bannerTitle]);

  function LoadMorelist() {
    setVisibleList((preValue) => preValue + 4);
  }

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  return (
    <div>
      <section className="job-list-hero blog-hero">
        {/* <LazyLoad className="AllCompImg blog-hero"> */}
        <img src={bannerImg} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{bannerTitle}</h1>
        </div>
      </section>
      <section className="new-jobs-section">
        <div className="container">
          <div className="new-jobs">
            <h2 className="top-employer-heading">Top Arbeitgeber</h2>
            <div className="items">
              {topEmployer.slice(0, visibleList).map((item, i) => (
                <div
                  className="item-card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={`${i * 2}00`}
                  data-aos-offset="100"
                >
                  <a
                    href={`${process.env.REACT_APP_HOST_URL}employer/${item.employer_slug}`}
                  >
                    <div className="jobs" key={i}>
                      <div className="image-wrapper">
                        <img
                          src={
                            item.profileIMG
                              ? item.profileIMG
                              : `${process.env.REACT_APP_HOST_URL}wp-content/uploads/2024/07/JobwishLogo.png`
                          }
                          alt=""
                        />
                      </div>

                      <div className="job-text">
                        <h3>{item.employer_Name}</h3>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.employer_bio
                              .split(" ")
                              .slice(0, 50)
                              .join(" "),
                          }}
                        />
                        <p>
                          <strong>Kontakt:</strong> {item.employer_contact}
                        </p>
                        <p>
                          <strong>Email:</strong> {item.employer_email}{" "}
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {visibleList < topEmployer.length ? (
        <button className="btn btn-red mx-auto my-5" onClick={LoadMorelist}>
          Mehr laden
        </button>
      ) : null}
    </div>
  );
}

export default TopEmployer;
