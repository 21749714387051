import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { useParams } from "react-router-dom";

function InterViewQuesResult() {
  const intrQuesParam = useParams();

  const [QuesData, setQuesData] = useState([]);
  const [bannerTitle, setBannerTitle] = useState();
  const [bannerImg, setBannerImg] = useState();
  let [visibleList, setVisibleList] = useState(10);

  useEffect(() => {
    let quesDetail = async () => {
      try {
        let intrViewQuesresponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/web-interviewQuesResult`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Catslug: intrQuesParam.slug,
              Catcount: intrQuesParam.count,
            }),
          }
        );

        let intrViewQuesData = await intrViewQuesresponse.json();
        // console.log(intrViewQuesData);
        setQuesData(intrViewQuesData.data);
      } catch (error) {
        // console.log("Error: ", error);
      }
    };
    quesDetail();
  }, []);

  useEffect(() => {}, [QuesData]);

  useEffect(() => {
    const bannerDetail = async () => {
      try {
        let bannerResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/664`
        );
        let bannerData = await bannerResponse.json();
        setBannerTitle(bannerData.title.rendered);
        let bannerImg = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${bannerData.featured_media}`
        );
        let bannerImgData = await bannerImg.json();
        setBannerImg(bannerImgData.guid.rendered);
      } catch (error) {
        // console.log("Error: ",error);
      }
    };
    bannerDetail();
  }, []);

  useEffect(() => {}, [bannerImg, bannerTitle]);

  function LoadMorelist() {
    setVisibleList((preValue) => preValue + 4);
  }

  return (
    <>
      <section className="job-list-hero blog-hero">
        {/* <LazyLoad className="InterViewBannerImg blog-hero"> */}
          <img src={bannerImg} alt="" loading="eager"/>
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{bannerTitle}</h1>
        </div>
      </section>
      <div className="container intrv-qun">
        <div className="outer-container">
          {QuesData.slice(0, visibleList).map((item, i) => (
            <div className="item" key={i}>
              <div className="item-row">
                <span>{i + 1}.</span>
                <h2>{item.Ques}</h2>
              </div>
              <p className="ans">{item.Ans}</p>
            </div>
          ))}
        </div>
        {visibleList < QuesData.length ? (
          <button className="btn btn-red" onClick={LoadMorelist}>
            Mehr laden
          </button>
        ) : null}
      </div>
    </>
  );
}

export default InterViewQuesResult;
