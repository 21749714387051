// import { a } from "@react-spring/web";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

function SearchEmployerResult() {
  let urlParam = useParams();

  let [employerDetail, setEmployerDetail] = useState();
  let [errorMessage, setErrorMessage] = useState();
  let [pageImg, setPageImg] = useState();

  useEffect(() => {
    let fetchEmp = async () => {
      let EmpResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/employerSearch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            employerTitle: urlParam.employerName ? urlParam.employerName : "",
            employerLocation: urlParam.jobLocation ? urlParam.jobLocation : "",
          }),
        }
      );

      let result = await EmpResponse.json();
      // console.log("result", result);
      if (result.data.status === 404) {
        setErrorMessage(result.data.message);
      } else {
        const searchData = result.data || []; // Ensure searchData is always an array
        setEmployerDetail(Array.isArray(searchData) ? searchData : []);
        setErrorMessage("");
      }
    };
    fetchEmp();
  }, [urlParam]);

  useEffect(() => {
    let fetchpage = async () => {
      let pageResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/imagesForWeb`
      );
      let ImgResult = await pageResponse.json();

      setPageImg(ImgResult.data.Search_Employer);
    };
    fetchpage();
  }, []);

  return (
    <>
      <section className="job-list-hero blog-hero">
        {/* <LazyLoad className="searchJob-banner blog-hero"> */}
        <img src={pageImg} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>Arbeitgebersuche</h1>
        </div>
      </section>
      {errorMessage ? (
        <div className="error">
          {errorMessage} <Link to={`/`}>{<span>Geh zurück</span>}</Link>
        </div>
      ) : (
        <section className="searched-employer-section">
          <div className="container">
            <div className="searched-employer-list">
              {employerDetail
                ? employerDetail.map((item, i) => (
                    <div className="single-employer-wrap" key={i}>
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}employer/${item.emp_slug}`}
                      >
                        <div className="single-employer-inner-wrap">
                          <div className="image-wrapper">
                            <img src={item.emp_Profile_Img} alt="" />
                          </div>

                          <div className="employer-details-wrap">
                            <div className="name-text-wrap">
                              <p>{item.emp_Name}</p>
                            </div>
                            <div className="other-details-wrap">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.emp_Bio
                                    .split(" ")
                                    .slice(0, 50)
                                    .join(" "),
                                }}
                              />
                              <p>
                                <strong>Contact:</strong> {item.emp_Phone}
                              </p>
                              <p>
                                <strong>Email:</strong> {item.emp_Email}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default SearchEmployerResult;
