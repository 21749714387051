import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazy-load";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos"; 
import "aos/dist/aos.css";

function Blog() {
  const [blogPage, setBlogpage] = useState();
  let [blogBannerHeading, setblogBannerHeading] = useState();
  let [blogBannerImg, setBlogbannerImg] = useState();
  const [blogpost, setBlogPost] = useState();
  const [servicesData, setServicesData] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    let blogPageData = async () => {
      // try {
        let blogPageResponse = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/pages/544`
        );
        let blogPageResponseData = await blogPageResponse.json();
        setBlogpage(blogPageResponseData);
      // } catch (error) {
      //   console.log("Error :", error);
      // }
    };
    blogPageData();
  }, []);

  useEffect(() => {
    if (blogPage) {
      // console.log(blogPage);
      setblogBannerHeading(blogPage.title.rendered);
    }
  }, [blogPage]);

  useEffect(()=>{
    const blog_page_images = async () => {
      let blogPageImageResponse = await fetch(`${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`);
      let imageResponse = await blogPageImageResponse.json();
      setBlogbannerImg(imageResponse.data.Blog_page);
    }
    setTimeout(() => {
      blog_page_images();
    }, 500);
  },[]);

  // useEffect(()=>{},[blogBannerImg]);

  useEffect(() => {
    let blogpostSec = async () => {
      let blogPostResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/posts`
      );
      let blogPostData = await blogPostResponse.json();
      setBlogPost(blogPostData);
    };
    setTimeout(() => {
      blogpostSec();
    }, 1000);
  }, []);

  useEffect(() => {
    const blogPostListData = async () => {
      if (!blogpost) {
        return;
      }

      const resultObject = {};
      for (const key in blogpost) {
        if (blogpost.hasOwnProperty(key)) {
          const item = blogpost[key];
          // console.log("item:", item);
          const mediaId = item.featured_media;
          try {
            // Fetch image URL using the WordPress REST API
            const response = await fetch(
              `${process.env.REACT_APP_HOST_URL}wp-json/wp/v2/media/${mediaId}`
            );
            const imageData = await response.json();
            // Update the result object with the image URL
            resultObject[key] = {
              img: imageData.source_url,
              heading: item.title.rendered,
              content: item.excerpt.rendered,
              time: item.date,
              PostId: item.id,
            };
          } catch (error) {
            // console.log("Error: ", error);
          }
        }
      }
      setServicesData(resultObject);
    };
    setTimeout(() => {
      blogPostListData();
    }, 1500);
  }, [blogpost]);

  useEffect(() => { AOS.init({ startEvent: 'load', }); }, []);

  return (
    <>
      <section className="blog-hero">
        {/* <LazyLoad className="BlogBanrImg blog-hero"> */}
          <img src={blogBannerImg} alt="" loading="eager"/>
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{blogBannerHeading}</h1>
        </div>
      </section>

      <section className="breadcb faq-breadcb">
        <div className="container">
          <div className="row">
            <div className="breadcrumb">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Categories"
                  href="/categories/"
                  className="archive post-product-archive"
                >
                  <span property="name">Startseite</span>
                </a>
                <meta property="position" content="1" />
              </span>
              <div className="bacsp">/</div>
              <span property="itemListElement" typeof="ListItem">
                <span
                  property="name"
                  className="archive taxonomy product_cat current-item"
                >
                  Blogs
                </span>
                <meta
                  property="url"
                  content="https://bromicpgstg.wpengine.com/product-category/gas-components/"
                />
                <meta property="position" content="2" />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="blog-card-wrap">
              {Object.keys(servicesData).map((key, i) => (
                <div className="blog-card" key={key} data-aos="fade-up" data-aos-duration="1000" data-aos-delay={`${i*2}00`} data-aos-offset="100">
                  <div className="blog-img">
                    {/* <LazyLoad className="PostImg blog-img"> */}
                      <img src={servicesData[key].img} alt="" />
                    {/* </LazyLoad> */}
                  </div>
                  <div className="blog-content">
                    <span>
                      {servicesData[key].time
                        ? servicesData[key].time.split("T")[0]
                        : null}
                    </span>
                    <h5>{servicesData[key].heading}</h5>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: servicesData[key].content,
                      }}
                    />
                    <Link
                      className="read-more2"
                      to={`/postdetail/${servicesData[key].PostId}`}
                      value={servicesData[key].PostId}
                    >
                      Mehr lesen
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Blog;
