import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [footerData, setFooterData] = useState("");
  let [footerHeading, setFooterHeading] = useState("");
  let [footerContent, setFooterContent] = useState("");
  let [footerContactHeading, setFooterContactHeading] = useState();
  let [footerContactMail, setfooterContactMail] = useState();
  let [socialMedia, setSocialMedia] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/footercontent`
        );
        const data = await response.json();
        setFooterData(data);
      } catch (error) {
        // console.error("Error: ", error);
      }
    };
setTimeout(()=>{ fetchData()}, 4000)
   // fetchData();
  }, []);

  useEffect(() => {
    if (footerData && footerData.data) {
      // console.log(footerData.acf);
      setFooterHeading(footerData.data.footer_heading);
      setFooterContent(footerData.data.footer_content);
      setFooterContactHeading(footerData.data.contact_to_team_heading);
      setfooterContactMail(footerData.data.contact_sub_heading);
      setSocialMedia(footerData.data.social_media);
    }
  }, [footerData]);

  const currentYear = new Date().getFullYear();

  const iframeUrl = `${process.env.REACT_APP_HOST_URL}/contact-form/`;

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-wrp">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-md-4 ">
              <h4>{footerHeading}</h4>

              <p className="footer_desc">{footerContent}</p>
              <div className="social-icons">
                {socialMedia
                  ? socialMedia.map((item, i) => (
                      <a
                        href={
                          item.social_media_link ? item.social_media_link : ""
                        }
                        target="_blank"
                        rel="noreferrer"
                        key={i}
                      >
                        {item.img ? (
                          <img src={item.img} alt="instagramImage" />
                        ) : null}
                      </a>
                    ))
                  : null}
              </div>
            </div>
            <div className="col-md-7 desktop-view">
              <div className="d-flex flex-wrap">
                <div className="col-md-4 footer-list">
                  <h5>Unternehmen</h5>
                  <ul className="list-style ">
                    <li>
                      <Link to="/about">Über uns</Link>
                    </li>
                    <li>
                      <Link to="/blog">Blog</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 footer-list">
                  <h5>Hilfe und Support</h5>
                  <ul className="list-style">
                    <li>
                      <Link to="/contactus">Kontaktieren Sie uns</Link>
                    </li>
                    <li>
                      <Link to="/faqs">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/business">Geschäft</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 footer-list">
                  <h5>Legal </h5>
                  <ul className="list-style">
                    <li>
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}term-conditions/`}
                      >
                        Allgemeine Geschäftsbedingungen
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_HOST_URL}privacy-policy/`}
                      >
                        Datenschutzrichtlinie
                      </a>
                    </li>
                    <li>
                      <a href={`${process.env.REACT_APP_HOST_URL}impressum/`}>
                        Impressum
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row stillHaveRow ">
            <div className="col-md-4 stillHaveQues">
              <h4 dangerouslySetInnerHTML={{ __html: footerContactHeading }} />
              <div className="mobile-footer-menu mobile-view">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                        Unternehmen
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <Link to="/about">Über uns</Link>
                        </p>
                        <p>
                          <Link to="/blog">Blog</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Legal
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <a
                            href={`${process.env.REACT_APP_HOST_URL}term-conditions/`}
                          >
                            Allgemeine Geschäftsbedingungen
                          </a>
                        </p>
                        <p>
                          <a
                            href={`${process.env.REACT_APP_HOST_URL}privacy-policy/`}
                          >
                            Datenschutzrichtlinie
                          </a>
                        </p>
                        <p>
                          <a
                            href={`${process.env.REACT_APP_HOST_URL}impressum/`}
                          >
                            Impressum
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Hilfe und Support
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                          <Link to="/contactus">Kontaktieren Sie uns</Link>
                        </p>
                        <p>
                          <Link to="/faqs">FAQ</Link>
                        </p>
                        <p>
                          <Link to="/business">Geschäft</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 p-0">
              <form className="search-container">
                <label className="">{footerContactMail}</label>
                <div className="align-items-center">
                  <iframe
                    sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation"
                    scrolling="auto"
                    title="Contact Form"
                    src={iframeUrl}
                    width="100%"
                    height="auto"
                    frameBorder="0"
                  ></iframe>
                </div>
              </form>
            </div>
          </div>
          <div className="copyright">
            © {currentYear} Alle Rechte vorbehalten von JobWish
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
