import React, { useEffect, useState } from "react";
// import LazyLoad from "react-lazy-load";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutUS() {
  const [aboutData, setAboutData] = useState();
  let [aboutBanner, setAboutBanner] = useState();
  let [bannerHeading, setBannerHeading] = useState();
  let [aboutHeading, setAboutHeading] = useState();
  let [aboutContentBold, setAboutContentBold] = useState();
  let [aboutImgContent, setaboutImgContent] = useState();
  let [aboutSecImg, setAboutSecImg] = useState();
  let [ourServicesList, setOurServicesList] = useState([]);
  let [service_heading, setService_heading] = useState();
  let [searchJob, setSearchJob] = useState();

  useEffect(() => {
    let aboutDataCall = async () => {
      // try {
      let aboutDataResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}wp-json/jobwish/v1/aboutus`
      );
      let data = await aboutDataResponse.json();
      setAboutData(data);
      // } catch (error) {
      //   console.log("Error : ", error);
      // }
    };
    setTimeout(() => {
      aboutDataCall();
    }, 1000);
  }, []);

  useEffect(() => {
    if (aboutData) {
      // console.log(aboutData);
      setBannerHeading(aboutData.data.banner_heading);
      setAboutHeading(aboutData.data.about_heading);
      setAboutContentBold(aboutData.data.about_content_bold);
      setaboutImgContent(aboutData.data.img_text);
      setOurServicesList(aboutData.data.our_services_list);
      setSearchJob(aboutData.data.search_job);
      setService_heading(aboutData.data.service_heading);
    }
  }, [aboutData]);

  // useEffect(()=>{console.log(searchJob)},[searchJob]);

  useEffect(() => {
    const about_Page_Images = async () => {
      let aboutPageImagesResponse = await fetch(
        `${process.env.REACT_APP_HOST_URL}/wp-json/jobwish/v1/imagesForWeb`
      );
      let imageResponse = await aboutPageImagesResponse.json();
      // console.log(imageResponse);
      setAboutBanner(imageResponse.data.About_page.about_BannerImage);
      setAboutSecImg(imageResponse.data.About_page.about_detailSecImg);
    };
    setTimeout(() => {
      about_Page_Images();
    }, 500);
  }, []);

  // useEffect(() => {}, [aboutBanner, aboutSecImg]);

  useEffect(() => {
    AOS.init({ startEvent: "load" });
  }, []);

  return (
    <>
      <section className="job-list-hero blog-hero">
        {/* <LazyLoad className="AboutBanrImg blog-hero"> */}
        <img src={aboutBanner} alt="" loading="eager" />
        {/* </LazyLoad> */}
        <div className="bnr-blog-content">
          <h1>{bannerHeading}</h1>
        </div>
      </section>

      <section className="breadcb about-us">
        <div className="container">
          <div className="row">
            <div className="breadcrumb">
              <span property="itemListElement" typeof="ListItem">
                <a
                  property="item"
                  typeof="WebPage"
                  title="Categories"
                  href="/categories/"
                  className="archive post-product-archive"
                >
                  <span property="name">Startseite</span>
                </a>
                <meta property="position" content="1" />
              </span>
              <div className="bacsp">/</div>
              <span property="itemListElement" typeof="ListItem">
                <span
                  property="name"
                  className="archive taxonomy product_cat current-item"
                >
                  Über uns{" "}
                </span>
                <meta
                  property="url"
                  content="https://bromicpgstg.wpengine.com/product-category/gas-components/"
                />
                <meta property="position" content="2" />
              </span>
            </div>
          </div>
          <div className="about-row">
            <div className="about-content">
              <h1 className="title">{aboutHeading}</h1>
              <p
                className="bold-text"
                dangerouslySetInnerHTML={{ __html: aboutContentBold }}
              />
            </div>
            <div className="about-collage">
              <img
                src={aboutSecImg}
                alt=""
                className="img-fluid"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-offset="200"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="aboutInfo">
        {aboutImgContent && aboutImgContent.length > 0
          ? aboutImgContent.map((item, i) =>
              i % 2 === 0 ? (
                <section className="business_integrate" key={i}>
                  <div className="container">
                    <div className="row-new">
                      <div className="col-md-6">
                        <div
                          className="business_integrate_img"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                          data-aos-offset="200"
                          data-aos-delay="500"
                        >
                          <img src={item.img} alt="integrate business" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="business_integrate_txt"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              ) : (
                <section className="business_recruit" key={i}>
                  <div className="container">
                    <div className="row-new">
                      <div className="col-md-6">
                        <div
                          className="business_recruit_txt"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div
                          className="business_recruit_img"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                          data-aos-offset="200"
                          data-aos-delay="500"
                        >
                          <img src={item.img} alt="recruit without risk" />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )
            )
          : null}
        {searchJob ? (
          <div className="custom-btn-link-wrapper">
            <a
              href={searchJob.url}
              className="btn btn-red "
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="100"
            >
              {searchJob.title}
            </a>
          </div>
        ) : null}
      </div>

      <section className="our-service">
        <div className="container">
          <h2 className="title">{service_heading}</h2>
          <div className="row">
            {/* Access data from servicesData object */}
            {ourServicesList &&
              ourServicesList.map((key, i) => (
                <div
                  className="item"
                  key={key}
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay={`${i * 2}00`}
                  data-aos-offset="200"
                >
                  <span>
                    <img src={ourServicesList[i].img} alt={key} />
                  </span>
                  <div className="content">
                    <h6>{ourServicesList[i].heading}</h6>
                    <p>{ourServicesList[i].content}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default AboutUS;
