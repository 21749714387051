import React, { useEffect } from 'react';

const GTMBodyNoScript = ({ gtmId }) => {
  useEffect(() => {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);

    return () => {
      document.body.removeChild(noscript); // Clean up on unmount
    };
  }, [gtmId]);

  return null; // This component does not render anything visually
};

export default GTMBodyNoScript;
